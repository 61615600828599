/**
 * Foundation Cards
 * + integration with Divider Mixin
 * + integration with Hover Transition Mixin
 */

$card-background: $white;
$card-font-color: $body-font-color;
$card-divider-background: $light-gray;
$card-border: 0;
$card-shadow: $shadow-depth1;
$card-border-radius: $global-radius;
$card-padding: $global-padding;
$card-margin-bottom: $global-margin;

// Divider
$card-divider-height: 10;

/**
 * Divider options:
 * forward
 * back
 * peak
 * valley
 * split
 * round
 * serrated
 */

$card-divider-style: 'forward';

/**
 * Position options:
 * top-outside
 * top-inside
 * bottom-outside
 * bottom-inside
 */

$card-divider-position: 'top-outside';

// Hover effect
$card-hover-icon-colour: transparent;

/**
 * Hover type options:
 * circle
 * square
 */

$card-hover-type: 'circle';

/**
 * Hover icon options:
 * @link https://github.com/thinknathan/sass-inline-icons/blob/master/sass-inline-icons.scss
 */

$card-hover-icon: 'maximize';

/**
 * Hover transition options:
 * fade
 * slide-up
 * slide-right
 * slide-down
 * slide-left
 */

$card-hover-transition: 'fade';

.card {
	@include card-container(
		$card-background,
		$card-font-color,
		$global-padding,
		$global-radius
	);

	max-width: rem-calc(600);
	box-shadow: none;
	// border-top: 2px solid $black;
	// border-bottom: 2px solid $black;

	.button {
		text-transform: uppercase;
		letter-spacing: 0.02em;
	}

	cursor: pointer;

	&:focus-within {
		// box-shadow: $shadow-depth3;
	}

	&__section {
		// @include card-section;
		position: relative;
		padding: 0 0 $card-padding;
	}

	&__title {
		margin-bottom: 0;
	}

	&__title-link {
		display: block;
		padding: $card-padding 0;
		color: $black;
	}

	&__meta {
		// @include caps;
		font-size: 0.9rem;
		opacity: 0.9;
		font-weight: bold;

		&--date {
			font-size: 0.8rem;
			letter-spacing: 0.04em;
			padding: $card-padding 0 $card-padding * 0.5;
			margin-bottom: -1rem;
		}
	}

	&__excerpt {
		line-height: 1.3;
		font-size: 0.85rem;
	}

	&__image {
		@include hover-transition(
			$card-hover-type,
			$card-hover-transition,
			0.66,
			$primary-color,
			$card-hover-icon,
			$card-hover-icon-colour,
			30,
			$primary-color
		);

		&::before {
			display: none;
		}

		min-height: 1px;

		> img {
			width: 100%;
			object-fit: cover;
		}
	}

	&__button {
		@include caps;
		color: inherit;
		background-color: transparent;
		font-weight: bold;
		padding: 0.7rem 1rem;
		border-bottom: 2px solid $primary-color;
		margin-bottom: 0;
	}

	&:not(&--image-first) {
		.card__image + .card__section {
			@include divider(
				$card-divider-style,
				$card-divider-height,
				$card-background,
				$card-divider-position
			);
		}
	}
}

// Add `.card--image-first` to swap the order of the image and title section
.card--image-first {
	.card__image {
		order: -1;
	}

	.card__section--head {
		@include divider(
			$card-divider-style,
			$card-divider-height,
			$card-background,
			$card-divider-position
		);

		padding-bottom: 0;

		&::before {
			z-index: 1;
		}
	}
}
