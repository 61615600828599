/**
 * Website footer
 */

$footer-bg: transparent;
$footer-link-color: $black;

.footer--site {
	background-color: $footer-bg;
	// color: $white;
	font-size: 1rem;
	position: relative;
	text-transform: uppercase;
	letter-spacing: 0.03em;
	padding: 2rem 0;

	.menu--submenu {
		display: none;
	}

	.menu--parent {
		&::after {
			display: none;
		}
	}

	a {
		color: $footer-link-color;

		// font-weight: bold;

		&:focus,
		&:hover {
			color: $primary-color;
		}
	}

	img {
		filter: grayscale(1) brightness(1.5) contrast(0.5);
		transition: 0.5s filter ease;
		&:hover {
			filter: none;
		}
	}

	&__inner {
		// justify-content: space-between;
		// @include flex-align($y: middle);
		// padding: $global-padding * 2 $global-padding;

		@include breakpoint(medium) {
		}
		@include breakpoint(large) {
			@include xy-grid;
			@include xy-grid-container;
			align-items: center;
			justify-content: flex-end;
		}
	}

	&__logo {
		// @include xy-cell('shrink');
		// border-top: 1px solid #eee;
		// padding-top: 1rem;
		margin-top: 1rem;

		@include breakpoint(large) {
			@include xy-cell(4 of 12);
			margin-top: 0;
			margin-right: auto;
		}
		.size-logo {
			max-width: rem-calc(73);
			max-height: rem-calc(112);
			// border: 1px solid $white;
			margin-right: 1rem;
			margin-bottom: 0;
		}
	}

	&__logo-wrap {
		display: flex;
		align-items: center;
	}

	&__nav {
		@include xy-cell();
		// border-top: 1px solid #eee;
		padding-top: 1rem;
		margin-top: 1rem;

		@include breakpoint(large) {
			@include xy-cell(4 of 12);
			margin-top: 0;
		}
		.menu--primary {
			align-items: flex-start;
			flex-direction: column;
			> .menu-item {
				margin-bottom: 0.5rem;
				> a {
					color: $white;
					padding: 0.25rem 0.25rem 0.25rem 0;
					margin-right: 1rem;
					line-height: 1.1;

					&::after {
						content: none !important;
					}

					&:focus,
					&:hover {
						color: $primary-color;
					}
				}
			}
		}
	}

	// &__address {
	// 	@include xy-cell();
	// 	// border-top: 1px solid #eee;
	// 	padding-top: 1rem;
	// 	margin-top: 1rem;

	// 	@include breakpoint(large) {
	// 		@include xy-cell(4 of 12);
	// 		margin-top: 0;
	// 	}
	// }

	// &__nav-title {
	// 	@include caps;
	// }

	&__subfooter {
		background-color: $footer-bg;
		color: $white;
		font-weight: bold;
	}

	&__subfooter-inner {
		@include xy-grid;
		@include xy-grid-container;
		@include caps;

		padding: 0 $global-padding $global-padding;
		justify-content: center;

		a {
			color: $white;

			&:focus,
			&:hover {
				color: $primary-color;
				opacity: 1;
			}
		}
	}

	&__copyright {
		@include xy-cell();
		text-align: center;
		margin-bottom: 0;

		@include breakpoint(large) {
			@include xy-cell('shrink');
			text-align: left;
		}
	}

	&__links {
		@include xy-cell();
		margin-top: 2rem;
		margin-bottom: 0;
		text-align: center;

		@include breakpoint(large) {
			@include xy-cell('shrink');
			margin-top: 0;
			text-align: left;
		}
	}

	&__link {
		font-size: 80%;
		text-transform: capitalize;
		text-decoration: underline;
		padding: 0.7rem 0;
	}

	&__credit {
		@include xy-cell();
		@include breakpoint(large) {
			@include xy-cell('shrink');
		}

		margin-bottom: 0;
		opacity: 0.5;

		a {
			font-weight: normal;
		}
	}

	.social-links__link {
		color: $white;
		font-size: 1rem;
		&:focus,
		&:hover {
			color: $primary-color;
		}
	}
}
