/**
 * Foundation Breadcrumbs
 */

$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: rem-calc(11);
$breadcrumbs-item-color: $primary-color;
$breadcrumbs-item-color-current: $black;
$breadcrumbs-item-color-disabled: $medium-gray;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: true;
$breadcrumbs-item-separator: true;
$breadcrumbs-item-separator-item: '/';
$breadcrumbs-item-separator-item-rtl: '\\';
$breadcrumbs-item-separator-color: $medium-gray;

.breadcrumbs {
	@include breadcrumbs-container;
	padding-top: 1rem;

	&--disabled {
		color: $breadcrumbs-item-color-disabled;
		cursor: not-allowed;
	}
}
