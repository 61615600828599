/**
 * Links to social media with icons
 */

.social-links {
	list-style: none;
	margin: 0;
	// display: flex;
	// justify-content: space-between;

	&__list-item {
		// display: inline-block;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}

	&__link {
		display: inline-block;
		// border-radius: 100rem;
		//  background-color: $primary-color;
		// width: 36px;
		// height: 36px;
		position: relative;
		font-weight: bold;
		color: $black;
		font-size: 0.8rem;
		letter-spacing: 0.02em;
		white-space: nowrap;
		filter: grayscale(1) brightness(1.5) contrast(0.5);
		transition: 0.5s filter ease;

		> img {
			display: inline-block;
			max-height: 20px;
			width: 20px;
			margin-right: 0.33rem;
			vertical-align: middle;
			// object-fit: contain;
			// position: absolute;
			// top: 50%;
			// left: 50%;
			// transform: translate(-50%, -50%);
		}

		&:hover,
		&:focus,
		&:active {
			//  background-color: $secondary-color;
			filter: none;
		}
	}
}
