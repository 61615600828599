/**
 * Offcanvas Toggle Button
 */

.offcanvas__toggle {
	margin-bottom: 0;
	font-size: 2.5rem;
	color: $black;
	background-color: transparent;
	&:hover,
	&:focus {
		color: $black;
		background-color: transparent;
		opacity: 0.66;
	}
}
