/**
 * Offcanvas Menu
 */

$menu-offcanvas-hover-background: $light-gray;

.offcanvas__content .menu--cta,
.menu--offcanvas {
	.menu-item {
		width: 100%;
	}

	.menu-item > a {
		color: $black;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-top: 0.9rem;
		padding-bottom: 0.9rem;
		font-size: 0.9rem;
		width: 100%;

		&:hover {
			background-color: lighten($menu-offcanvas-hover-background, 5%);
		}

		&:focus,
		&:active {
			background-color: $menu-offcanvas-hover-background;
			color: $primary-color;
		}
	}

	.mm-active {
		> a {
			color: $black;
		}
	}

	.menu--submenu {
		a {
			padding-left: 3rem;
			opacity: 0.9;

			&:hover,
			&:focus,
			&:active {
				opacity: 1;
			}
		}
	}

	.menu--parent {
		&::after {
			@include css-triangle($dropdownmenu-arrow-size, $primary-color, down);

			right: 1.5rem;
		}

		> a {
			padding-right: 2.5rem;
		}
	}
}
