/**
 * Main SCSS file.
 * Structure based on Robert Rendle's The “Other” Interface: Atomic Design With Sass
 * Most imports are disabled by default for performance. Enable what you need.
 * @link https://www.smashingmagazine.com/2013/08/other-interface-atomic-design-sass/
 */

/* ==========================================================================
 * Utilities
 * Definition: the basic plumbing of the website
 * Usage: global classes, mixins and styles that can be used anywhere and at any time
   ========================================================================== */

@mixin caps {
	// font-family: Figtree, sans-serif;
	// text-transform: uppercase;
	// letter-spacing: 0.035em;
}

/* Dependencies */

@import '~foundation-sites/scss/foundation';
@import '~sass-inline-icons/sass-inline-icons';
@import '~sass-divider/sass-divider';
@import '~sass-hover-transition/sass-hover-transition';
@import '~sass-ripple/sass-ripple';

/* Helper Plugins and Setup */

@import 'utilities/global';
@import 'utilities/wp-classes';
// @import "utilities/themer";

/* ==========================================================================
 * Quarks
 * Definition: classless HTML elements
 * Usage: think globally and don't style for any particular section of the design
   ========================================================================== */

@import 'quarks/forms';
@import 'quarks/images';
// @import "quarks/table";
@import 'quarks/typography';
@import 'quarks/links';

/* ==========================================================================
 * Atoms
 * Definition: common, re-usable components
 * Usage: use low-specificity classes; should almost never be attached to an HTML element itself
   ========================================================================== */

/* Foundation - Controls */

@import 'atoms/button';
// @import "atoms/button-group";
// @import "atoms/switch";

/* Foundation - Navigation */

@import 'atoms/menu';
// @import "atoms/pagination";
@import 'atoms/breadcrumbs';

/* Foundation - Containers */

@import 'atoms/callout';
@import 'atoms/card';
// @import "atoms/media-object";

/* Foundation - Media */

// @import "atoms/badge";
// @import "atoms/responsive-embed";
// @import "atoms/label";
// @import "atoms/progress-bar";
// @import "atoms/thumbnail";

/* Gravity Forms Styles */

@import 'atoms/gform-base';
@import 'atoms/gform-progress';
@import 'atoms/gform-textarea';
@import 'atoms/gform-select';
@import 'atoms/gform-numbers';
@import 'atoms/gform-checkbox-radio';
@import 'atoms/gform-section-html';
@import 'atoms/gform-complex';
@import 'atoms/gform-date';
// @import 'atoms/gform-datepicker';
// @import 'atoms/gform-upload';
// @import 'atoms/gform-tables';
// @import 'atoms/gform-pricing';
@import 'atoms/gform-errors';
@import 'atoms/gform-hidden';

/* Custom */

@import 'atoms/accordion';
// @import "atoms/carousel";
// @import "atoms/dialogmodal";
// @import "atoms/datepicker";
// @import 'atoms/gmap';
// @import "atoms/icon";
// @import "atoms/lightbox";
// @import "atoms/placeholder";
@import 'atoms/offcanvas-toggle';
@import 'atoms/social-links';
// @import "atoms/social-share";
@import 'atoms/tabs';

/* ==========================================================================
 * Molecules
 * Definition: one-off structures you don’t intend to replicate
 * Usage: extend, modify and combine other styles and modules
   ========================================================================== */

// @import 'molecules/base';
@import 'molecules/blocks';
@import 'molecules/callout-privacy';
@import 'molecules/footer-site';
@import 'molecules/header-site';
// @import "molecules/menu-navbar";
@import 'molecules/menu-offcanvas';
@import 'molecules/menu-primary';
@import 'molecules/offcanvas';
@import 'molecules/page-login';
@import 'molecules/sidebar';

body {
	font-optical-sizing: auto;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	letter-spacing: -0.015em;
}

.nolist {
	margin-left: 0;
	list-style: none;
}

.menu a {
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.02em;
}

.home-bg {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	object-fit: cover;
	width: 100%;
	height: 100%;
	user-select: none;
	pointer-events: none;
	-webkit-user-drag: none;
	background-color: #a39e98;
}

.home {
	.content--site {
		position: relative;
	}

	.sidebar--site__content {
		.menu .menu-item > a {
			color: $white;
			&:focus,
			&:hover,
			&:active {
				background-color: rgba($white, 0.1);
			}
		}
		.menu--parent::after {
			border-top-color: $white;
		}
		img {
			filter: invert(1);
		}
	}

	.footer--site {
		color: $white;
		a {
			color: $white;
			&:hover,
			&:focus {
				color: $primary-color;
			}
		}
		img {
			opacity: 0.8;
		}
	}

	.footer--site__logo-wrap {
		.size-logo {
			filter: brightness(2);
		}
	}

	.sidebar--site__content {
		border-color: $white;
	}
}

.brand-icon {
	width: 36px;
	height: 36px;
	margin-right: 1rem;
}

.wp-block-heading {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	> .brand-icon-in-heading {
		order: -1;
	}
}

.displaynone {
	display: none;
}

.list--recent-work {
	border-top: 2px solid $black;
}

.card--recent-work {
	padding: 1rem;
	// box-shadow: $shadow-depth1;
	border-bottom: 2px solid $black;
	// margin-top: 1rem;
	// margin-bottom: 1rem;
	min-height: 6rem;
	@include xy-grid;
	@include flex-align($y: middle);
	&__image {
		@include xy-cell(4 of 12);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		border-right: 2px solid;
		padding-right: 0.625rem;

		> img {
			max-width: 100%;
			height: auto;
		}
	}
	&__section {
		@include xy-cell(auto);
		:last-child {
			margin-bottom: 0;
		}
	}
	&__value {
		@include xy-cell(2 of 12);
		border-left: 2px solid;
		padding-left: 0.625rem;
		white-space: nowrap;
		text-align: center;

		&-prefix {
			font-size: 120%;
			opacity: 0.8;
		}
		&-number {
			font-size: 200%;
		}
		&-suffix {
			font-size: 120%;
			opacity: 0.8;
		}
	}
}

.sort.asc > span::after {
	opacity: 1;
}

.sort.desc > span::after {
	opacity: 1;
	transform: translateY(-50%) rotate(180deg);
}

.sort > span {
	position: relative;
	padding-right: 0.6rem;
}

.sort > span::after {
	content: '';
	display: inline-block;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid;
	position: relative;
	top: 50%;
	right: -4px;
	transform: translateY(-50%);
	transition: transform 0.2s ease-in, opacity 0.2s ease-in;
	opacity: 0;
}

.gform_wrapper {
	padding: 0 1rem 1rem;
	background-color: $white;
	// box-shadow: $shadow-depth1;
	border-top: 2px solid $black;
	border-bottom: 2px solid $black;

	.gfield_label {
		font-weight: normal;
		text-transform: uppercase;
		font-size: 80%;
		opacity: 0.8;
		padding-top: 0.25rem;
		letter-spacing: 0.02em;
	}
}

ul {
	list-style-type: square;
}

.acf-gmap {
	width: 100%;
	height: rem-calc(400);
	position: relative;
	overflow: hidden;
	&__placeholder {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 50%;
		right: 0;
		bottom: 0;
		left: 50%;
		opacity: 1;
		transform: translate(-50%, -50%);
	}
}

.block--profile {
	&__inner {
		@include xy-grid();
	}
	&__content {
		@include xy-cell();
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
		@include breakpoint(medium) {
			@include xy-cell(8 of 12);
		}
		@include breakpoint(xlarge) {
			@include xy-cell(9 of 12);
		}
		> p > b {
			@include caps;
			font-weight: bold;
		}
	}
	&__image {
		display: block;
		margin-bottom: 1rem;
	}
	&__button {
		display: block;
		margin-bottom: 1rem;
		border: 1px solid $primary-color;
		background-color: transparent;
		color: $black;

		&:hover {
			.block--profile__icon-email {
			}
		}
	}
	&__icon-email {
		border-radius: 2px;
		display: inline-block;
		background-color: $white;
	}

	&__role {
		@include caps;
		font-weight: bold;
		order: -1;
	}
	&__extra {
		@include xy-cell();
		margin-bottom: 1rem;
		order: -1;

		@include breakpoint(medium) {
			@include xy-cell(4 of 12);
		}
		@include breakpoint(xlarge) {
			@include xy-cell(3 of 12);
		}
	}
}

.error404 {
	.main {
		@include xy-grid-container();
		padding: 1rem;
	}
}

.index-grid {
	@include xy-grid-container();
	padding-bottom: 1rem;
	@include breakpoint(xlarge) {
		padding-bottom: 2rem;
	}

	&__inner {
		@include xy-grid;
		> .card {
			@include xy-cell();
			@include breakpoint(medium) {
				@include xy-cell(6 of 12);
			}
			@include breakpoint(xlarge) {
				@include xy-cell(4 of 12);
			}
		}
	}
}

.article-post {
	@include xy-grid-container();
	padding: 1rem;

	> .content {
		> h2,
		> h3 {
			> img {
				display: none;
			}
		}
	}
}

.page-template-template-constrained {
	.main {
		@include xy-grid-container();
		padding: 1rem;
	}
}

.button {
	text-wrap: balance;
}

.has-email {
	word-break: break-all;
}

.block--profile__role {
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

.sort-controls {
	text-transform: uppercase;
	padding: 1rem 0 2rem;
}

.sort-label {
	display: inline-block;
	margin-right: 1rem;
}

.sort {
	text-transform: uppercase;
	color: $primary-color;
}
