/**
 * Anchor tags
 */

$anchor-color: $primary-color;
$anchor-transition: $button-transition;

a {
	color: $anchor-color;
	transition: $anchor-transition;

	&:focus,
	&:hover {
		color: darken($anchor-color, 10%);
	}
}
