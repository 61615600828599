/**
 * Website header
 */

$navigation-breakpoint-toggle: large;
$navigation-bg: $white;
$navigation-color: $dark-gray;
$navigation-link-color: $dark-gray;

.header--site {
	@include hide-for(large);
	background-color: $navigation-bg;
	position: sticky;
	z-index: 4;
	top: 0;
	left: 0;
	right: 0;
	// box-shadow: $shadow-depth2;
	border-bottom: 2px solid $black;
	color: $navigation-color;

	&.on-hero {
		background-color: rgba($white, 0.5);
		box-shadow: none;
		border-bottom: 2px solid transparent;
	}

	a {
		color: $navigation-link-color;

		&:focus,
		&:hover {
			color: $primary-color;
		}
	}

	&__inner {
		@include xy-grid;
		// @include xy-grid-container;
		@include flex-align($y: middle);
		position: relative;

		@include breakpoint(large) {
			min-height: rem-calc(73);
		}

		@include breakpoint(jumbo) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		// padding: $global-minimum-margin;
	}

	&__brand {
		@include xy-cell('shrink');
		@include breakpoint(large) {
			@include xy-cell(3 of 16);
		}

		@include breakpoint(xlarge) {
			@include xy-cell(4 of 16);
		}

		@include breakpoint(jumbo) {
			@include xy-cell(2 of 16);
		}
	}

	&__title {
		line-height: 0;
		margin: 0;
	}

	&__title-link {
		display: block;
		// margin-top: -$global-minimum-margin;
		// margin-bottom: $global-minimum-margin * -4.5;
		> .size-logo {
			max-width: rem-calc(73);
			max-height: rem-calc(112);
		}
	}

	&__navigation {
		@include xy-cell(auto);
		// @include flex-align($x: center);

		text-align: center;

		@include breakpoint($navigation-breakpoint-toggle) {
			text-align: left;
		}

		.menu--primary {
			@include show-for($navigation-breakpoint-toggle);
			@include flex-align($x: center);
			justify-content: space-around;
		}
	}

	&__final {
		@include xy-cell('shrink');
		text-align: right;

		@include breakpoint(large) {
			@include xy-cell(3 of 16);
		}

		@include breakpoint(xlarge) {
			@include xy-cell(4 of 16);
		}

		@include breakpoint(jumbo) {
			@include xy-cell(2 of 16);
		}

		.offcanvas__toggle {
			@include hide-for($navigation-breakpoint-toggle);
		}
		.nav--cta {
			@include show-for($navigation-breakpoint-toggle);
			> .menu--cta {
				display: block;
				margin-left: auto;
				> .menu-item > a {
					color: $black;

					&:focus,
					&:hover {
						color: $primary-color;
					}
				}
			}
		}
	}
}
