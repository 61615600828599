/**
 * WordPress Generated Classes
 * @link https://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

// Media alignment
.alignnone {
	margin-left: 0;
	margin-right: 0;
	max-width: 100%;
	height: auto;
}

.aligncenter {
	display: block;
	margin: $global-margin auto;
	height: auto;
}

.alignleft,
.alignright {
	margin-bottom: $global-margin;
	height: auto;
}

@include breakpoint(medium) {
	// Only float if not on an extra small device
	.alignleft {
		float: left;
		margin-right: $global-margin;
	}

	.alignright {
		float: right;
		margin-left: $global-margin;
	}
}

// Captions
.wp-caption {
	line-height: normal;
}

.wp-caption-text {
	padding: $global-padding;
	font-size: 0.8rem;
}

// Text meant only for screen readers
.screen-reader-text,
.show-for-sr {
	@include element-invisible;
}

.show-on-focus {
	// Only display the element when it's focused
	&:active,
	&:focus {
		@include element-invisible-off;
	}
}
