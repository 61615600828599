/**
 * Base Typography
 */

$header-font-family: $body-font-family;
$header-font-weight: normal;
$header-font-style: normal;
$font-family-monospace: consolas, 'Liberation Mono', courier, monospace;
$header-color: inherit;
$header-lineheight: 1.2;
$header-margin-bottom: 0.5rem;
$header-styles: (
	small: (
		'h1': (
			'font-size': 28,
		),
		'h2': (
			'font-size': 23,
		),
		'h3': (
			'font-size': 19,
		),
		'h4': (
			'font-size': 16,
		),
		'h5': (
			'font-size': 16,
		),
		'h6': (
			'font-size': 16,
		),
	),
	medium: (
		'h1': (
			'font-size': 45,
		),
		'h2': (
			'font-size': 32,
		),
		'h3': (
			'font-size': 23,
		),
		'h4': (
			'font-size': 16,
		),
		'h5': (
			'font-size': 16,
		),
		'h6': (
			'font-size': 16,
		),
	),
	xlarge: (
		'h1': (
			'font-size': 45,
		),
		'h2': (
			'font-size': 36,
		),
		'h3': (
			'font-size': 23,
		),
		'h4': (
			'font-size': 16,
		),
		'h5': (
			'font-size': 16,
		),
		'h6': (
			'font-size': 16,
		),
	),
);
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$enable-code-inline: true;
$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$enable-cite-block: true;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

/*
 * Typography Helpers
 */
$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $dark-gray;
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2.5rem;
$cite-color: $dark-gray;
$cite-font-size: rem-calc(13);
$cite-pseudo-content: '\2014 \0020';
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);
$code-block-padding: 1rem;
$code-block-margin-bottom: 1.5rem;

// Temporary hack to workaround these classes
// not existing when optionally extended
%cite-block {
}
%code-inline {
}

@include foundation-typography-base;
//@include foundation-typography-helpers;
//@include foundation-text-alignment;
@include foundation-print-styles;

// Prevent text overflow on pre
pre {
	overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include caps;
}

body {
	@include breakpoint(xlarge) {
		font-size: 105%;
	}

	@include breakpoint(jumbo) {
		font-size: 110%;
	}
}
