/**
 * Off-Canvas Panel
 */

$offcanvas-size: 300px;
$offcanvas-position-offset: 32px;
$offcanvas-header-background: $white;
$offcanvas-content-background: $light-gray;
$offcanvas-shadow: $shadow-depth4;
$offcanvas-overlay-zindex: 10;
$offcanvas-transition-length: 0.3s;
$offcanvas-transition-timing: $global-transition-curve;
$offcanvas-exit-background: rgba($black, 0.5);

.offcanvas {
	background-color: $offcanvas-content-background;
	height: 100%;
	left: calc(100% + rem-calc($offcanvas-position-offset));
	overflow-y: auto;
	max-width: rem-calc($offcanvas-size);
	position: fixed;
	top: 0;
	width: 100%;
	box-shadow: $offcanvas-shadow;
	z-index: $offcanvas-overlay-zindex;
	transform: translateX(0);
	transition: transform $offcanvas-transition-length
		$offcanvas-transition-timing;

	&.is-ready {
		&[aria-hidden='false'] {
			transform: translateX(
				rem-calc(-$offcanvas-position-offset - $offcanvas-size)
			);
		}

		&[aria-hidden='true'] {
			transition: transform $offcanvas-transition-length
					$offcanvas-transition-timing,
				visibility 0.016s $offcanvas-transition-length;
		}
	}

	&__overlay {
		visibility: hidden;
		opacity: 0;
		transition: opacity $offcanvas-transition-length
				$offcanvas-transition-timing,
			visibility 0.016s $offcanvas-transition-length;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: $offcanvas-overlay-zindex - 1;
		background-color: $offcanvas-exit-background;
	}

	&.is-active {
		& + .offcanvas__overlay {
			visibility: visible;
			opacity: 1;
			transition: opacity $offcanvas-transition-length
				$offcanvas-transition-timing;
		}
	}

	// &__head {
	// 	padding: $global-padding;
	// 	background-color: $offcanvas-header-background;

	// 	.size-logo {
	// 		max-width: rem-calc(73);
	// 		max-height: rem-calc(112);
	// 	}

	// }

	// &__controls {
	// 	padding: $global-minimum-margin;
	// 	background-color: $offcanvas-header-background;
	// 	text-align: right;
	// }

	&__content {
		.offcanvas__toggle {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}

		padding: 2rem 0 3rem;
		background-color: $white;
		position: relative;

		@include divider(
			$card-divider-style,
			$card-divider-height,
			$card-background,
			'bottom-outside'
		);
	}

	&__inner {
		padding: 0 0.5rem;
		margin-left: 1.5rem;
		margin-right: 2rem;
		border-left: 3px solid #adaca9;
	}

	&__title {
		display: inline-block;
		margin-left: 1rem;
		margin-top: 3rem;
		opacity: 0.5;
	}

	&__contact {
		padding: 2rem 1rem;
	}

	&:not(.is-ready) .offcanvas__close {
		visibility: hidden;
	}
}
