/**
 * Privacy call-out
 */

$privacy-callout-breakpoint: medium;

.callout--privacy {
	position: fixed;
	bottom: 0;
	left: 0.5rem;
	right: 0.5rem;
	box-shadow: $shadow-depth5;
	z-index: 8;
	font-size: 0.8rem;
	border: 0;

	&--inactive {
		display: none;
	}

	&__inner {
		@include breakpoint($privacy-callout-breakpoint) {
			@include xy-grid;
			@include flex-align($y: middle);
		}
	}

	&__content {
		@include breakpoint($privacy-callout-breakpoint) {
			@include xy-cell(auto);

			margin-bottom: 0;
		}
	}

	.button-group {
		margin-bottom: 0;

		@include breakpoint($privacy-callout-breakpoint) {
			@include xy-cell(auto);

			max-width: 30%;
		}
	}

	.button {
		margin-bottom: 0;
	}
}
