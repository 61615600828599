/**
 * Form and Input tags
 */

$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: rem-calc(16);
$form-label-color: $black;
$form-label-font-size: rem-calc(14);
$form-label-font-weight: $global-weight-normal;
$form-label-line-height: 1.8;
$select-background: $white;
$select-triangle-color: $dark-gray;
$select-radius: $global-radius;
$input-color: $black;
$input-placeholder-color: $medium-gray;
$input-font-family: inherit;
$input-font-size: rem-calc(16);
$input-font-weight: $global-weight-normal;
$input-line-height: $global-lineheight;
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: 1px solid darken($light-gray, 8%);
$input-border-focus: 1px solid $dark-gray;
$input-padding: $form-spacing * 0.5;
$input-shadow: inset 0 1px 2px rgba($black, 0.1);
$input-shadow-focus: 0 0 5px $medium-gray;
$input-cursor-disabled: not-allowed;
$input-transition: box-shadow 0.5s, border-color 0.25s $global-transition-curve;
$input-number-spinners: true;
$input-radius: 3px;
$form-button-radius: $global-radius;

// Make sure textarea takes on height automatically
textarea {
	height: auto;
	min-height: 50px;
	border-radius: $global-radius;
}

// Make select elements are 100% width by default
select {
	box-sizing: border-box;
	width: 100%;
	border-radius: $global-radius;
}

// Make reset inherit font-family instead of settings sans-serif
button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
}

// Reset <button> styles created by most browsers
button {
	@include disable-mouse-outline;

	padding: 0;
	appearance: none;
	border: 0;
	border-radius: $global-radius;
	background: transparent;
	line-height: 1;
	cursor: $global-button-cursor;
}

@include foundation-form-text;
@include foundation-form-checkbox;
@include foundation-form-label;
@include foundation-form-fieldset;
@include foundation-form-select;
