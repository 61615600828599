/**
 * Image tags
 */

img {
	// Get rid of gap under images by making them display: inline-block; by default
	display: inline-block;
	vertical-align: middle;

	// Grid defaults to get images and embeds to work properly
	max-width: 100%;
	height: auto;
	-ms-interpolation-mode: bicubic;
	user-drag: none;
	user-select: none;
}
