/**
 * Foundation Callout
 */

$callout-background: $white;
$callout-background-fade: 85%;
$callout-border: 1px solid rgba($black, 0.25);
$callout-margin: 0 0 1rem 0;
$callout-sizes: (
	small: 0.5rem,
	default: 1rem,
	large: 3rem,
);
$callout-font-color: $body-font-color;
$callout-font-color-alt: $body-background;
$callout-radius: $global-radius;

.callout {
	@include callout;

	@each $name, $color in $foundation-palette {
		&--#{$name} {
			@include callout-style($color);
		}
	}

	&--small {
		@include callout-size(0.5rem);
	}

	&--large {
		@include callout-size(3rem);
	}
}
