/**
 * Gravity Forms - Progress Styles
 *
 * Copyright © 2022 WP Site Care <hello@wpsitecare.com>
 * 
 * Permission is hereby granted, free of charge, to any person
 * obtaining a copy of this software and associated documentation
 * files (the "Software"), to deal in the Software without
 * restriction, including without limitation the rights to use,
 * copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the
 * Software is furnished to do so, subject to the following
 * conditions:
 * 
 * The above copyright notice and this permission notice shall be
 * included in all copies or substantial portions of the Software.
 * 
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES
 * OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
 * NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
 * HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
 * WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
 * FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR
 * OTHER DEALINGS IN THE SOFTWARE.
 */

.gform_wrapper {
	.gf_progressbar_wrapper {
		margin: 0 0 $gravity-margin-small;
		padding: 0 0 $gravity-margin-small;

		@include breakpoint(large) {
			margin-bottom: $gravity-margin-medium;
			padding-bottom: $gravity-margin-medium;
		}

		.gf_progressbar_title {
			font-size: $gravity-medium-font-size;
			font-weight: normal;
		}
	}

	.gf_progressbar {
		background: $gravity-light-gray;
		border: 1px solid $gravity-medium-fog;
		height: 30px;
		line-height: 30px;
		overflow: hidden;
	}

	.gf_step {
		display: inline-block;
		height: $gravity-title-font-size;
		line-height: 1.25;
		margin: 0 16px 10px 0;
		opacity: 0.2;

		span {
			vertical-align: top;
		}
	}

	.gf_progressbar_percentage {
		font-size: $gravity-medium-font-size;
		height: 30px;
		text-align: right;
		text-shadow: 0 1px 1px rgba($gravity-off-black, 0.5);

		span {
			display: block;
			float: right;
			margin: 0 5px;
			width: auto;
		}

		&.percentbar_0 span {
			color: $gravity-light-gray;
			text-shadow: none;
		}
	}

	.percentbar_blue {
		background-color: $gravity-blue;
		color: $gravity-white;
	}

	.percentbar_gray {
		background-color: $gravity-gray;
		color: $gravity-white;
	}

	.percentbar_green {
		background-color: $gravity-green;
		color: $gravity-white;
	}

	.percentbar_orange {
		background-color: $gravity-orange;
		color: $gravity-white;
	}

	.percentbar_red {
		background-color: $gravity-red;
		color: $gravity-white;
	}

	.gf_page_steps {
		border-bottom: 1px dotted $gravity-light-gray;
		margin: 0 0 $gravity-margin-small;
		padding: 0 0 $gravity-margin-small;

		@include breakpoint(large) {
			margin-bottom: $gravity-margin-medium;
			padding-bottom: $gravity-margin-medium;
		}
	}

	.gf_step_active {
		opacity: 1;
	}

	.gf_step_number {
		font-size: $gravity-title-font-size;
	}
}
