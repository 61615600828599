/**
 * Accordion
 */

// Global
$accordion-radius: 0;
$accordion-margin-bottom: $global-margin;
// Headers
$accordion-header-background: transparent;
$accordion-header-text-transform: uppercase;
$accordion-header-color: inherit;
$accordion-header-font-size: inherit;
$accordion-header-padding: $global-padding;
$accordion-header-focus-background: transparent;
$accordion-header-focus-color: $primary-color;
$accordion-header-active-background: transparent;
$accordion-header-active-color: $primary-color;
$accordion-arrow-size: $dropdownmenu-arrow-size;
$accordion-arrow-color: $primary-color;
$accordion-header-outline: $global-outline;
// Panel
$accordion-panel-background: transparent;
$accordion-panel-color: inherit;
$accordion-panel-font-size: inherit;
$accordion-panel-padding: $global-padding 0;
$accordion-panel-outline: none;

.accordion {
	margin-bottom: $accordion-margin-bottom;

	&__header {
		@include caps;
		display: block;
		cursor: pointer;
		background-color: $accordion-header-background;
		color: $accordion-header-color;
		line-height: 1.2;
		margin-bottom: 0;
		padding: $accordion-header-padding;
		padding-left: 2.5rem;
		position: relative;
		text-decoration: none;
		text-transform: $accordion-header-text-transform;
		transition: background-color 0.2s $global-transition-curve;
		border-bottom: 2px solid $black;

		font-size: 1rem;

		@include breakpoint(medium) {
			font-size: 1.1rem;
		}

		@include breakpoint(large) {
			font-size: 1.2rem;
		}

		// Triangle indicator
		&::after {
			@include css-triangle($accordion-arrow-size, $black, down);
			transform: translateY(-50%);
			position: absolute;
			left: 1rem;
			top: 50%;
			transition: transform 0.2s $global-transition-curve;
		}

		&[aria-selected='true'] {
			background-color: $accordion-header-active-background;
			color: $accordion-header-active-color;
			// Triangle indicator when active
			&::after {
				@include css-triangle(
					$accordion-arrow-size,
					$accordion-arrow-color,
					down
				);
				transform: translateY(-50%) rotate(180deg);
			}
		}

		&[aria-selected='false']:focus,
		&[aria-selected='false']:hover {
			background-color: $accordion-header-focus-background;
			color: $accordion-header-focus-color;
		}

		&:focus {
			outline: $accordion-header-outline;
		}

		@if ($accordion-radius > 0) {
			&:first-child {
				border-radius: $accordion-radius $accordion-radius 0 0;
			}

			&:last-of-type:not([aria-selected='true']) {
				border-radius: 0 0 $accordion-radius $accordion-radius;
			}
		}
	}

	&__panel {
		background-color: $accordion-panel-background;
		color: $accordion-panel-color;
		font-size: $accordion-panel-font-size;
		outline: $accordion-panel-outline;
		visibility: hidden;
		height: 0;

		&[aria-hidden='false'] {
			visibility: visible;
			.accordion__inner {
				transform: scaleY(100%);
				opacity: 1;
			}
		}

		@if ($accordion-radius > 0) {
			&:last-child {
				border-radius: 0 0 $accordion-radius $accordion-radius;
			}
		}
	}

	&__inner {
		padding: $accordion-panel-padding;
		transform-origin: top center;
		transition: transform 0.3s $global-transition-curve,
			opacity 0.6s $global-transition-curve;
		transform: scaleY(0);
		opacity: 0;
		margin-bottom: 1rem;

		> :last-child {
			margin-bottom: 0;
		}
	}
}
