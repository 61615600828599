/**
 * Buttons
 */

$button-font-family: inherit;
$button-font-weight: bold;
$button-padding: 0.8rem 1rem;
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-background: $primary-color;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: $white;
$button-color-alt: $black;
$button-radius: 3px;
$button-border: 1px solid transparent;
$button-hollow-border-width: 1px;
$button-sizes: (
	tiny: 0.6rem,
	small: 0.75rem,
	default: 0.9rem,
	large: 1.25rem,
);
$button-palette: $foundation-palette;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -50%;
$button-transition: background-color 0.25s $global-transition-curve,
	color 0.25s $global-transition-curve, opacity 0.25s $global-transition-curve;

.button {
	@include button;
	@include ripple;

	letter-spacing: 0.04em;
	line-height: 1.2;
	text-decoration: none;

	// Full width button
	&--expanded {
		@include button-expand;
	}

	// Colours
	@each $name, $color in $button-palette {
		@if $button-fill !=hollow {
			&--#{$name} {
				@include button-style($color, auto, auto);
			}
		} @else {
			&--#{$name} {
				@include button-hollow-style($color);
			}
		}
	}

	// Disabled style
	&--disabled,
	&[disabled] {
		@include button-disabled;
	}

	@each $name, $color in $button-palette {
		&--#{$name}--disabled {
			@include button-disabled($color);
		}
	}

	// Hollow style
	@if $button-fill !=hollow {
		&--hollow {
			@include button-hollow;
			@include button-hollow-style;
		}

		@each $name, $color in $button-palette {
			&--#{$name}--hollow {
				@include button-hollow-style($color);
			}
		}
	}

	// Clear style
	@if $button-fill !=clear {
		&--clear {
			@include button-hollow;
			@include button-hollow-style;

			border-color: transparent;

			&:hover,
			&:focus {
				border-color: transparent;
			}
		}

		@each $name, $color in $button-palette {
			&--#{$name}--clear {
				@include button-hollow-style($color);

				border-color: transparent;

				&:hover,
				&:focus {
					border-color: transparent;
				}
			}
		}
	}
}

// .wp-block-button__link {
// 	@extend .button;
// }

.wp-block-buttons {
	padding-bottom: 1rem;
}

.wp-block-button__link {
	@include caps;
	color: inherit;
	background-color: transparent;
	font-weight: bold;
	padding: 0.7rem 1rem;
	border-bottom: 2px solid $primary-color;
}
