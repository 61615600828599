/*! Copyright © 2022 WP Site Care @license MIT <http://wpsitecare.mit-license.org> */

/**
 * Gravity Forms - Base Styles
 *
 * Copyright © 2022 WP Site Care <hello@wpsitecare.com>
 * 
 * Permission is hereby granted, free of charge, to any person
 * obtaining a copy of this software and associated documentation
 * files (the "Software"), to deal in the Software without
 * restriction, including without limitation the rights to use,
 * copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the
 * Software is furnished to do so, subject to the following
 * conditions:
 * 
 * The above copyright notice and this permission notice shall be
 * included in all copies or substantial portions of the Software.
 * 
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES
 * OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
 * NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
 * HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
 * WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
 * FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR
 * OTHER DEALINGS IN THE SOFTWARE.
 */

@use 'sass:math';

// Gravity Forms Colors
// -----------
$gravity-black: $black;
$gravity-off-black: $black;
$gravity-gray: $dark-gray;
$gravity-light-gray: $light-gray;
$gravity-light-fog: $light-gray;
$gravity-medium-fog: $medium-gray;
$gravity-fog: $medium-gray;
$gravity-white: $white;
$gravity-blue: #0072bc;
$gravity-yellow: #fff2aa;
$gravity-green: #94dc21;
$gravity-orange: #dc7021;
$gravity-red: #dc2521;

// Gravity Forms Layout Options
// -----------
$gravity-margin: $global-margin;
$gravity-margin-medium: math.div($global-margin, 2);
$gravity-margin-small: math.div($global-margin, 3);
$gravity-form-input-padding: $input-padding;

// Base Font Sizes
// -----------
$gravity-medium-font-size: $global-font-size;
$gravity-small-font-size: $small-font-size;
$gravity-title-font-size: 18px;

.gform_wrapper {
	text-align: left;

	ul {
		margin-left: 0;

		& > li {
			list-style-type: none;
			margin: $gravity-margin 0 0;
		}
	}

	input,
	select {
		margin-bottom: 0;
	}

	input + label {
		display: inline-block;
	}

	.gform_title,
	.gsection_title {
		font-size: $gravity-title-font-size;
	}

	.gform_fields {
		padding: 0;
	}

	.gfield {
		clear: both;
	}

	.gfield_label {
		clear: both;
		display: inline-block;
		font-weight: 700;
		margin: $gravity-margin-small 0;

		@include breakpoint(large) {
			margin-top: $gravity-margin-medium;
		}
	}

	.gfield_description {
		font-size: $gravity-medium-font-size;
	}

	.gfield_required {
		color: $gravity-red;
		padding-left: 2px;
	}

	.field_description_above {
		.gfield_description {
			margin-bottom: $gravity-margin-small;

			@include breakpoint(large) {
				margin-bottom: $gravity-margin-medium;
			}
		}
	}

	.ginput_container {
		@include breakpoint(medium) {
			.small {
				@include xy-cell($size: 25%, $gutters: 0);
			}

			.medium {
				@include xy-cell($size: 50%, $gutter-position: right);
			}

			.large {
				@include xy-cell($gutters: 0);
			}
		}
	}

	.gform_footer {
		margin-top: $gravity-margin;
	}
}
