/*! Copyright © 2011-2020 ZURB, Inc. @license MIT <https://github.com/foundation/foundation-sites/blob/develop/LICENSE> */

/**
 * Global Foundation Settings
 */

$global-font-size: 100%;
$global-width: rem-calc(1440-32);
$global-lineheight: 1.4;
$global-outline: default;
$global-margin: 1rem;
$global-minimum-margin: 0.5rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.75rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$global-transition-curve: cubic-bezier(0.4, 0, 0.2, 1);
$body-font-family: 'DM Sans', -apple-system, blinkmacsystemfont, 'Segoe UI',
	roboto, oxygen-sans, ubuntu, cantarell, 'Helvetica Neue', sans-serif;
$body-safe-padding: false;
$body-antialiased: true;
$print-transparent-backgrounds: true;
$print-hrefs: true;

/*
 * Palette
 */
$foundation-palette: (
	'primary': #8b0094,
	'secondary': #8b0094,
	'light': #fff,
	'success': #3adb76,
	'warning': #ffae00,
	'alert': #cc4b37,
);
$light-gray: #f9f9f9;
$medium-gray: #adaca9;
$dark-gray: #757575;
$black: #000000;
$white: #fff;
$body-background: $white;
$body-font-color: $dark-gray;

/*
 * Material Design Shadow Depth
 */
// Based on Material depth z1
$shadow-depth1: 0px 2px 1px -1px rgba($black, 0.2),
	0px 1px 1px 0px rgba($black, 0.14), 0px 1px 3px 0px rgba($black, 0.12);
// Based on Material depth z3
$shadow-depth2: 0px 3px 3px -2px rgba($black, 0.2),
	0px 3px 4px 0px rgba($black, 0.14), 0px 1px 8px 0px rgba($black, 0.12);
// Based on Material depth z5
$shadow-depth3: 0px 3px 5px -1px rgba($black, 0.2),
	0px 5px 8px 0px rgba($black, 0.14), 0px 1px 14px 0px rgba($black, 0.12);
// Based on Material depth z7
$shadow-depth4: 0px 4px 5px -2px rgba($black, 0.2),
	0px 7px 10px 1px rgba($black, 0.14), 0px 2px 16px 1px rgba($black, 0.12);
// Based on Material depth z9
$shadow-depth5: 0px 5px 6px -3px rgba($black, 0.2),
	0px 9px 12px 1px rgba($black, 0.14), 0px 3px 16px 2px rgba($black, 0.12);

/*
 * Breakpoints
 */
$breakpoints: (
	small: 0,
	medium: 640px,
	large: 1024px,
	xlarge: 1200px,
	xxlarge: 1440px,
	jumbo: 1680px,
	mammoth: 1920px,
);
$breakpoints-hidpi: (
	hidpi-1: 1,
	hidpi-1-5: 1.5,
	hidpi-2: 2,
	retina: 2,
	hidpi-3: 3,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large);

/*
 * Flexbox Utilities
 */
$flex-source-ordering-count: 6;
$flexbox-responsive-breakpoints: true;

/*
 * XY Grid
 */
$xy-grid: true;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: (
	small: 20px,
	medium: 30px,
);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: $grid-padding-gutters;
$grid-container-max: $global-width;
$xy-block-grid-max: 8;

@import '~foundation-sites/scss/util/util';

@include add-foundation-colors;
@include foundation-normalize;

html {
	box-sizing: border-box;
	font-size: $global-font-size;

	// Smooth scrolling on the whole document
	scroll-behavior: smooth;
}

// Set box-sizing globally to handle padding and border widths
*,
*::before,
*::after {
	box-sizing: inherit;
}

// Default body styles
body {
	margin: 0;
	padding: 0;
	background: $body-background;
	font-family: $body-font-family;
	font-weight: $global-weight-normal;
	line-height: $global-lineheight;
	color: $body-font-color;

	@if ($body-antialiased) {
		-webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
		-moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
	}
}

/**
 * Remove motion for user-agents requesting reduced motion
 * Hat tip Nick/cssremedy
 * @link https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/#comment-1700170)
*/
@media (prefers-reduced-motion: reduce) {
	*,
	*::before,
	*::after {
		animation-duration: 0.001s !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.001s !important;
		scroll-behavior: auto !important;
	}
}
