/**
 * Tabs
 */

// Global
$tabs-margin-bottom: $global-margin;
// Tabs
$tabs-color: inherit;
$tabs-background: transparent;
$tabs-spacing: $global-minimum-margin;
$tabs-radius: $global-radius;
$tabs-padding: 0.7rem 0;
$tabs-font-size: inherit;
$tabs-outline: $global-outline;
$tabs-focus-color: inherit;
$tabs-focus-background: transparent;
$tabs-active-color: inherit;
$tabs-active-background: transparent;
// Panel
$tabs-panel-padding: 1rem 0;
$tabs-panel-background: transparent;
$tabs-panel-color: inherit;
$tabs-panel-font-size: inherit;
$tabs-panel-outline: none;

.tabs {
	margin-bottom: $tabs-margin-bottom;

	/* Tablist */
	&__tablist {
		margin: 0 0 1rem 0;
		list-style: none;

		// Add .tabs__tablist--scroll to prevent the tab list from wrapping
		&--scroll {
			display: flex;
			flex-wrap: nowrap;
			align-items: stretch;
			max-width: 100%;
			overflow: auto;
		}
	}

	&__tablist-item {
		display: inline-block;
		margin-right: 0.7rem;
	}

	&__tab {
		color: $tabs-color;
		display: inline-block;
		height: 100%;
		margin-right: $tabs-spacing;
		font-size: $tabs-font-size;
		line-height: 1.2;
		text-decoration: none;
		background-color: $tabs-background;
		border-radius: $tabs-radius $tabs-radius 0 0;
		padding: $tabs-padding;
		text-transform: uppercase;
		transition: background-color 0.2s $global-transition-curve,
			color 0.2s $global-transition-curve;
		border-bottom: 2px solid;

		&[tabindex='0'] {
			// background-color: $tabs-active-background;
			// color: $tabs-active-color;
			color: $primary-color;
		}

		&:not([tabindex='0']):hover {
			// background-color: $tabs-focus-background;
			// color: $tabs-focus-color;
			filter: contrast(2);
		}

		&:hover,
		&:focus {
			outline: $tabs-outline;
		}
	}

	&:not(.is-ready) &__tablist {
		opacity: 0.25;
	}

	/* Tabpanel */
	&__panel {
		background-color: $tabs-panel-background;
		color: $tabs-panel-color;
		font-size: $tabs-panel-font-size;
		outline: $tabs-panel-outline;

		&[aria-hidden='true'] {
			visibility: hidden;
			height: 0;
			pointer-events: none;

			> .tabs__inner {
				transform: scaleY(0.5) translateY(-50%);
				opacity: 0;
			}
		}
	}

	&__inner {
		padding: $tabs-panel-padding;
		transform-origin: top center;
		transition: transform 0.3333s var($global-transition-curve),
			opacity 0.6666s var($global-transition-curve);

		> :last-child {
			margin-bottom: 0;
		}
	}
}
