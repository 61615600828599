/**
 * WordPress Login Screen styles
 * @link https://codex.wordpress.org/Customizing_the_Login_Form
 */

body.login {
	background: $primary-color;
	text-align: center;

	// Custom Logo
	h1 > a {
		background-size: contain;
		max-width: 100%;
		max-height: rem-calc(300);
	}

	// Login form
	form {
		border-radius: $global-radius;
		box-shadow: $shadow-depth3;
		background: $white;
		border: none;
		color: $black;
		text-align: left;
	}

	// Customize footer links
	.privacy-policy-page-link,
	#nav,
	#backtoblog {
		> a {
			transition: opacity 0.3s $global-transition-curve;
			color: $white;
			opacity: 0.8;

			&:focus,
			&:active,
			&:hover {
				color: $white;
				opacity: 1;
			}
		}
	}
}
