/**
 * Menu
 */

$menu-margin: $global-margin 0;
$menu-nested-margin: $global-menu-nested-margin;
$menu-items-padding: $global-menu-padding;
$menu-simple-margin: 1rem;
$menu-item-color-active: $white;
$menu-item-color-alt-active: $black;
$menu-item-background-active: transparent;
$menu-icon-spacing: 0.25rem;
$menu-item-background-hover: transparent;
$menu-state-back-compat: true;
$menu-centered-back-compat: true;
$menu-icons-back-compat: true;
$menu-transition-curve: $global-transition-curve;
$menu-transition-length: 0.3s;

.menu {
	@include menu-base;
	@include caps;

	// Default orientation: horizontal
	&,
	&--horizontal {
		@include menu-direction(horizontal);
	}

	// Vertical orientation modifier
	&--vertical {
		@include menu-direction(vertical);
	}

	// Even-width modifier for horizontal orientation
	&--expanded {
		@include menu-expand;
	}

	// Simple
	&--simple {
		@include menu-simple;
	}

	// Align left
	&--align-#{$global-left} {
		@include menu-align(left);
	}

	// Align right
	&--align-#{$global-right} {
		@include menu-align(right);
	}

	// Align center
	&--align-center {
		@include menu-align(center);
	}

	// Parent menus
	&--parent {
		position: relative;

		// Dropdown arrow for parent menus
		&::after {
			@include css-triangle(
				$dropdownmenu-arrow-size,
				$dropdownmenu-arrow-color,
				down
			);

			position: absolute;
			transition: transform $menu-transition-length $menu-transition-curve;
			top: 1.3rem;
			right: 0.3125rem;
			left: auto;
			transform: translateY(-50%);
		}

		&.mm-active::after {
			transform: translateY(-50%) rotate(-180deg);
		}

		> a {
			padding-right: 1.5rem;
		}
	}

	// Metis menu animation
	.mm-collapse,
	.mm-collapsing {
		visibility: hidden;
		height: 0;
		opacity: 0;
	}

	// Metis menu animation
	.mm-collapsing {
		transition: opacity $menu-transition-length * 0.5 $menu-transition-curve,
			height $menu-transition-length $menu-transition-curve,
			visibility 0.016s $menu-transition-length;
	}

	// Metis menu animation
	.mm-show {
		visibility: visible;
		height: unset;
		opacity: 1;
		transition: opacity $menu-transition-length $menu-transition-curve,
			height $menu-transition-length $menu-transition-curve;
	}
}
