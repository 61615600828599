/**
 * Gravity Forms - Complex Styles
 *
 * Copyright © 2022 WP Site Care <hello@wpsitecare.com>
 * 
 * Permission is hereby granted, free of charge, to any person
 * obtaining a copy of this software and associated documentation
 * files (the "Software"), to deal in the Software without
 * restriction, including without limitation the rights to use,
 * copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the
 * Software is furnished to do so, subject to the following
 * conditions:
 * 
 * The above copyright notice and this permission notice shall be
 * included in all copies or substantial portions of the Software.
 * 
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES
 * OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
 * NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
 * HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY,
 * WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
 * FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR
 * OTHER DEALINGS IN THE SOFTWARE.
 */

@use 'sass:map';

$gform-complex-gutter: rem-calc(map.get($grid-margin-gutters, 'medium') * 0.5);
$gform-complex-gutter-half: $gform-complex-gutter * 0.5;

.gform_wrapper {
	.ginput_complex {
		@include breakpoint(medium) {
			@include xy-grid;
		}

		.ginput_full {
			@include breakpoint(medium) {
				@include xy-cell($gutters: 0);
			}
		}

		.name_first {
			@include breakpoint(medium) {
				@include xy-cell($size: auto, $gutters: 0);
			}
		}
		.name_middle,
		.name_last {
			@include breakpoint(medium) {
				@include xy-cell($size: auto, $gutter-position: left);
			}
		}

		.ginput_left {
			@include breakpoint(medium) {
				@include xy-cell(
					$gutters: $gform-complex-gutter,
					$gutter-position: right
				);
				width: calc(50% - $gform-complex-gutter-half);
			}
		}

		.ginput_right {
			@include breakpoint(medium) {
				@include xy-cell(
					$gutters: $gform-complex-gutter,
					$gutter-position: left
				);
				width: calc(50% - $gform-complex-gutter-half);
			}
		}
	}

	/**
	* Set a complex form to show its sublabels above
	* The overall label will be hidden
	* Eg. Instead of Name (First) (Last)
	* It will just show (First) (Last)
	*/
	.field_sublabel_above {
		> .gfield_label_before_complex {
			@include element-invisible;
		}
		> div > span > label {
			font-weight: bold;
			margin: $gravity-margin-medium 0 $gravity-margin-small;
		}
	}
}
