.block--hero {
	// margin-top: rem-calc(-73);
	// max-width: 100%;
	// overflow: hidden;

	&__inner {
		padding-bottom: 3rem;
		@include breakpoint(large) {
			min-height: 70vh;
		}

		// width: 100%;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		overflow: hidden;
		position: relative;
	}
	&__img {
		max-width: none;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		object-fit: cover;

		.has-anim-pan & {
			width: 150%;
			transform: translateX(-33%);
			animation: backgroundScroll;
		}
		.has-anim-zoom & {
			background-position: center center;
			transform: scale(1.1);
			animation: backgroundZoom;
		}

		.has-anim-linear & {
			animation-timing-function: linear;
		}
		.has-anim-ease & {
			animation-timing-function: ease;
		}

		.has-anim-loop & {
			animation-iteration-count: infinite;
		}
		.has-anim-no-loop & {
			animation-iteration-count: 1;
		}

		.has-anim-ten & {
			animation-duration: 12s;
		}
		.has-anim-thirty & {
			animation-duration: 30s;
		}
		.has-anim-fortyfive & {
			animation-duration: 45s;
		}
		.has-anim-sixty & {
			animation-duration: 60s;
		}
	}
	&__content {
		position: relative;
		// min-width: 50vw;
		max-width: rem-calc(580);
		border-top: 2px solid $white;
		border-bottom: 2px solid $white;
		text-align: center;
		margin: auto;

		@include breakpoint(large) {
			text-align: left;
			margin: 0;
		}

		// @include breakpoint(large) {
		// 	max-width: 50vw;
		// }
	}
	&__quote {
		padding: 1.5rem 1rem;
		position: relative;
		z-index: 2;
		// left: 0;
		// right: 0;
		// top: 100%;
		// transform: translateY(-50%);

		// text-align: center;
		// transition: opacity 0.8s ease-in 1.6s;

		@include breakpoint(large) {
			padding: 2rem 3rem;
		}

		&.inactive {
			z-index: 1;
			// opacity: 0;
		}
	}
	&__quote + &__quote {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
	}
	&__quote-title {
		display: inline-block;
		color: $white;
		font-weight: normal;
		margin-bottom: 1.5rem;
		transition: opacity 1s ease-in 1s, mask-size 0.6s ease-in 0.8s;
		border-bottom: 3px solid $primary-color;

		// Mask 100% area
		mask-image: url('data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTAgMGgxMDAwdjEwMDBoLTEwMDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0ibTIxNSAxMjJoMzIwdjQ1MmgtMzIweiIgdHJhbnNmb3JtPSJtYXRyaXgoMy4xMjUgMCAwIDIuMjEyMzkgLTY3MS44NzUgLTI2OS45MTIpIi8+PC9zdmc+');
		mask-size: 100%;
		mask-repeat: no-repeat;

		.inactive & {
			opacity: 0;
			mask-size: 1px;
			transition-delay: 0.25s;
		}

		@include breakpoint(large) {
			font-size: 3.25rem;
		}
	}
	&__quote-content {
		@include caps;
		// display: inline-block;
		color: $white;
		transition: opacity 1s ease-in 1.2s, mask-size 0.7s ease-in 1.6s,
			text-decoration-color 0.2s ease-in 2.4s;
		text-wrap: pretty;
		// text-decoration: underline;
		// text-decoration-color: transparent;

		// Mask 100% area
		mask-image: url('data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTAgMGgxMDAwdjEwMDBoLTEwMDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0ibTIxNSAxMjJoMzIwdjQ1MmgtMzIweiIgdHJhbnNmb3JtPSJtYXRyaXgoMy4xMjUgMCAwIDIuMjEyMzkgLTY3MS44NzUgLTI2OS45MTIpIi8+PC9zdmc+');
		mask-size: 100%;
		mask-repeat: no-repeat;

		.inactive & {
			// opacity: 0;
			mask-size: 1px;
			// transition-delay: 0.1s;
			transition: opacity 1s ease-in 0.1s, mask-size 0.7s ease-in 0.1s,
				text-decoration-color 0.2s ease-in 2s;
			// text-decoration-color: $primary-color;
		}

		> p {
			line-height: 1.2;
		}

		@include breakpoint(large) {
			font-size: 1.66rem;
		}
	}
	&__quote-link {
		display: inline-block;
		transition: opacity 1s ease-in 1.5s, color 0.33s ease,
			mask-size 0.4s ease-in 2s;
		color: $white;
		// Mask 100% area
		mask-image: url('data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTAgMGgxMDAwdjEwMDBoLTEwMDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0ibTIxNSAxMjJoMzIwdjQ1MmgtMzIweiIgdHJhbnNmb3JtPSJtYXRyaXgoMy4xMjUgMCAwIDIuMjEyMzkgLTY3MS44NzUgLTI2OS45MTIpIi8+PC9zdmc+');
		mask-size: 100%;
		mask-repeat: no-repeat;

		.inactive & {
			// opacity: 0;
			mask-size: 1px;
			transition-delay: 0.25s;
			// pointer-events: none;
		}

		&:hover,
		&:active {
			color: $primary-color;
		}

		.brand-icon-in-heading {
			width: 18px;
			height: auto;
			margin-right: 0.5rem;
		}
	}

	&__controls {
		color: $white;
		margin-top: 1rem;
		position: absolute;
		left: 50%;
		bottom: -3rem;
		transform: translateX(-50%);
		@include breakpoint(large) {
			left: 0;
			transform: none;
		}
	}
	&__previous,
	&__next {
		color: inherit;
		padding: 0.33rem;
		cursor: pointer;
		transition: transform 0.33s ease;
		transform-origin: center center;
		&:hover {
			transform: scale(1.25);
		}
	}
	&__current-item {
		padding-left: 0.33rem;
	}
	&__decorative-separator {
	}
	&__total-items {
		padding-right: 0.33rem;
	}
	&__pause {
		margin-left: 1rem;
		padding: 0.33rem;
		cursor: pointer;
		transition: transform 0.33s ease;
		transform-origin: center center;
		&:hover {
			transform: scale(1.25);
		}
		g {
			transition: fill 0.33s ease;
		}
		&.is-active {
			g {
				fill: $primary-color;
			}
		}
		&.is-inactive {
		}
	}
}

// .anim-fade-in-up {
// 	animation: fadeInUp 1s ease-in;
// }
// .anim-fade-in-down {
// 	animation: fadeInDown 1s ease-in;
// }
// .anim-fade-out-up {
// 	animation: fadeOutUp 1s ease-in;
// }
// .anim-fade-out-down {
// 	animation: fadeOutDown 1s ease-in;
// }

// @keyframes fadeInUp {
// 	0% {
// 		transform: translateY(100%);
// 		opacity: 0;
// 	}
// 	100% {
// 		transform: translateY(0%);
// 		opacity: 1;
// 	}
// }

// @keyframes fadeInDown {
// 	0% {
// 		transform: translateY(-100%);
// 		opacity: 0;
// 	}
// 	100% {
// 		transform: translateY(0%);
// 		opacity: 1;
// 	}
// }

// @keyframes fadeOutUp {
// 	0% {
// 		transform: translateY(0%);
// 		opacity: 1;
// 	}
// 	100% {
// 		transform: translateY(-100%);
// 		opacity: 0;
// 	}
// }

// @keyframes fadeOutDown {
// 	0% {
// 		transform: translateY(0%);
// 		opacity: 1;
// 	}
// 	100% {
// 		transform: translateY(100%);
// 		opacity: 0;
// 	}
// }

@keyframes backgroundZoom {
	0% {
		transform: scale(1);
		opacity: 0.9;
	}
	100% {
		transform: scale(1.1);
		opacity: 1;
	}
}

@keyframes backgroundScroll {
	0% {
		transform: translateX(-33%);
	}
	50% {
		transform: translateX(0%) scale(1.3);
	}
	100% {
		transform: translateX(-33%);
	}
}

.block--box {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;

	&__inner {
		position: relative;
		> :last-child {
			margin-bottom: 0;
		}
	}

	//

	&.is-top-aligned {
	}
	&.is-middle-aligned {
		justify-content: center;
	}
	&.is-bottom-aligned {
		justify-content: flex-end;
	}

	//

	&.is-full-width {
	}
	&.is-constrained {
		@include xy-grid-container;
	}
	&.is-narrow {
		@include xy-grid-container;
		max-width: rem-calc(974);
		@include breakpoint(large) {
			margin-left: 0;
		}
	}

	//

	&.has-no-padding {
	}
	&.has-padding {
		padding: 1rem;
		@include breakpoint(xxlarge) {
			padding: 1.5rem;
		}
	}
	&.has-more-padding {
		padding: 2rem 1rem;
		@include breakpoint(xxlarge) {
			padding: 4rem 1.5rem;
		}
	}

	//

	&.is-transparent-bg {
	}
	&.is-white-bg {
		background-color: $white;
		// color: $black;
		// text-shadow: none;
		// a:not(.button):not(.card__title-link) {
		// 	color: $secondary-color;
		// 	transition: opacity 0.2s $global-transition-curve;
		// 	&:hover,
		// 	&:focus {
		// 		opacity: 0.8;
		// 	}
		// }
	}
	&.is-gray-bg {
		background-color: $light-gray;
	}

	//

	&.has-no-border {
	}
	&.has-top-border {
		@include divider('forward', 32, $white, 'top-outside');
	}
	&.is-gray-bg.has-top-border {
		@include divider('forward', 32, $light-gray, 'top-outside');
	}

	//

	&.has-no-shift {
	}
	&.has-up-shift {
		transform: translateY(rem-calc(-32));
		// margin-bottom: rem-calc(-32);
		z-index: 1;
		@include breakpoint(medium) {
			transform: translateY(rem-calc(-48));
			// margin-bottom: rem-calc(-48);
		}
	}
	&.has-down-shift {
		transform: translateY(rem-calc(32));
		// margin-top: rem-calc(-32);
		z-index: 1;
		@include breakpoint(medium) {
			transform: translateY(rem-calc(48));
			// margin-top: rem-calc(-48);
		}
	}
}

/**
* Banner
*/

.block--banner {
	&__img {
		width: 100%;
		object-fit: cover;
		max-height: 70vh;
	}
}
