/**
 * Primary Menu
 */

$menu-p-active-bar-height: 0.125rem;
$menu-p-active-bar-color: $primary-color;
$menu-p-submenu-background: $white;
$menu-p-submenu-color: $dark-gray;
$menu-p-submenu-border-radius: $global-radius;
$menu-p-submenu-background: $white;
$menu-p-submenu-shadow: $shadow-depth3;
$menu-p-dropdown-arrow-color: $white;

.menu--primary {
	@include flex-align($x: right, $y: middle);

	// Active menu item
	> .menu-item {
		> [aria-current='page'] {
			position: relative;

			&::after {
				content: '';
				position: absolute;
				height: $menu-p-active-bar-height;
				background-color: $menu-p-active-bar-color;
				width: calc(100% - 2rem);
				bottom: -$menu-p-active-bar-height;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	// Dropdown arrow for parent menus
	.menu--parent {
		&::after {
			display: none;
		}
	}

	// Dropdown menu
	.menu--submenu {
		position: absolute;
		left: 50%;
		transform: scaleY(0.01) translateX(-50%);
		transform-origin: top;
		background-color: $menu-p-submenu-background;
		box-shadow: $menu-p-submenu-shadow;
		min-width: $dropdownmenu-min-width;
		padding-top: $global-minimum-margin;
		padding-bottom: $global-minimum-margin;
		border-radius: $menu-p-submenu-border-radius;

		a {
			color: $menu-p-submenu-color;
			&:hover,
			&:focus {
				color: $primary-color;
			}
		}
	}

	// Metis menu animation
	.mm-collapsing {
		transition: transform $menu-transition-length $menu-transition-curve,
			opacity $menu-transition-length $menu-transition-curve,
			height 0.016s $menu-transition-length,
			visibility 0.016s $menu-transition-length;
	}

	// Metis menu animation
	.mm-show {
		transform: scaleY(1) translateX(-50%);
		transition: transform $menu-transition-length $menu-transition-curve,
			opacity $menu-transition-length $menu-transition-curve, height 0.016s;
	}

	// Don't centre last submenu so it doesn't stretch off the screen
	> li:last-of-type {
		> .menu--submenu {
			transform: scaleY(0.01);
			right: 0;
			left: auto;
		}

		// Metis menu animation
		> .mm-show {
			transform: scaleY(1);
		}
	}
}
