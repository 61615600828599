/**
 * Sidebar
 */

.sidebar--site {
	@include show-for(large);
	position: sticky;
	top: 1rem;
	padding-left: 1rem;

	@include breakpoint(large) {
		top: 2rem;
	}
	@include breakpoint(xxlarge) {
		padding: 1rem 2rem;
	}

	&__content {
		border-left: 2px solid;
		padding-left: 1rem;
		max-width: rem-calc(280);

		> .menu,
		> .nav--cta > .menu {
			width: 100%;
			margin-bottom: 2rem;
		}
	}

	&__title {
		margin-left: 1rem;
	}
}

.main {
	padding: 1rem;

	@include breakpoint(large) {
		padding: 2rem 1rem;
	}
	@include breakpoint(xxlarge) {
		padding: 3rem;
	}
}

body {
	@include breakpoint(large) {
		// @include xy-grid();
		display: flex;
		flex-wrap: wrap;

		.main {
			width: 77%;
			order: 1;
			max-width: rem-calc(1132);
			margin-left: auto;
		}
		.sidebar--site {
			width: 23%;
			order: 2;
			max-height: 100vh;
			overflow: auto;
			max-width: rem-calc(280);
			margin-right: auto;
		}
		.footer--site {
			width: 100%;
			order: 3;
			// flex-grow: 1;
		}
	}
}

// body {
// 	@include breakpoint(large) {
// 		overflow: hidden;
// 	}
// }
